<template>
    <div class="okr-dashboard">
        <!-- Summary Cards -->
        <div class="summary-cards">
            <div class="okr-card total-okr">
                <h6 class="mb-0">Total OKR</h6>
                <h4 class="mb-0">234</h4>
            </div>
            <div class="okr-card live-okrs" v-b-tooltip.hover.v-default v-b-tooltip.top title="107 People Submitted OKRs">
                <h6 class="mb-0">Live OKRs</h6>
                <h4 class="mb-0">107</h4>
            </div>
            <div class="okr-card pending-approval" v-b-tooltip.hover.v-default v-b-tooltip.top title="65 OKRs are yet to be Approved by Managers">
                <h6 class="mb-0">OKRs Pending Approval</h6>
                <h4 class="mb-0">65</h4>
            </div>
            <div class="okr-card empty-okrs" v-b-tooltip.hover.v-default v-b-tooltip.top title="48 People have not Created OKR">
                <h6 class="mb-0">Empty OKRs</h6>
                <h4 class="mb-0">48</h4>
            </div>
        </div>
        <!-- Main Section with Progress Image -->
        <div class="okr-main">
          <b-row>
            <b-col md="6">
              <b-card>
                <h5>Zircly Defaulters Q3 2024</h5>
                <ul class="p-0 m-0 default-okr">
                  <li class="list-unstyled my-1">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <a href="/profile/7ag6m" rel="noopener" target="_blank" class="b-avatar mr-75 badge-secondary rounded-circle" style="width: 40px; height: 40px; background: transparent;">
                          <span class="b-avatar-img">
                            <b-avatar src="../../../assets/images/avatars/1.png" alt="user-image" width="32px" height="32px"/>
                          </span>
                        </a>
                        <div class="profile-user-info mt-25 cursor-pointer">
                          <h6 class="mb-0">Annette Black</h6>
                          <small> uX Designer</small>
                        </div>
                      </div>
                        <div class="d-flex align-items-center gap-40">
                          <!-- <b-badge :variant="getStatusVariant(row.item.review_status)">
                            <div class="dot"></div> {{ row.item.review_status }}
                          </b-badge> -->
                          <b-badge variant="light-danger" class="p-50"> <div class="dot"></div> Not Created</b-badge>
                          <b-button pill variant="outline-secondary">Send Alert</b-button>
                        </div>
                    </div>
                  </li>
                  <li class="list-unstyled my-1">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <a href="/profile/7ag6m" rel="noopener" target="_blank" class="b-avatar mr-75 badge-secondary rounded-circle" style="width: 40px; height: 40px; background: transparent;">
                          <span class="b-avatar-img">
                            <b-avatar src="../../../assets/images/avatars/1.png" alt="user-image" width="32px" height="32px"/>
                          </span>
                        </a>
                        <div class="profile-user-info mt-25 cursor-pointer">
                          <h6 class="mb-0">Annette Black</h6>
                          <small> uX Designer</small>
                        </div>
                      </div>
                        <div class="d-flex align-items-center gap-40">
                          <!-- <b-badge :variant="getStatusVariant(row.item.review_status)">
                            <div class="dot"></div> {{ row.item.review_status }}
                          </b-badge> -->
                          <b-badge variant="light-danger" class="p-50"> <div class="dot"></div> Not Created</b-badge>
                          <b-button pill variant="outline-secondary">Send Alert</b-button>
                        </div>
                    </div>
                  </li>
                  <li class="list-unstyled my-1">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <a href="/profile/7ag6m" rel="noopener" target="_blank" class="b-avatar mr-75 badge-secondary rounded-circle" style="width: 40px; height: 40px; background: transparent;">
                          <span class="b-avatar-img">
                            <b-avatar src="../../../assets/images/avatars/1.png" alt="user-image" width="32px" height="32px"/>
                          </span>
                        </a>
                        <div class="profile-user-info mt-25 cursor-pointer">
                          <h6 class="mb-0">Annette Black</h6>
                          <small> uX Designer</small>
                        </div>
                      </div>
                        <div class="d-flex align-items-center gap-40">
                          <!-- <b-badge :variant="getStatusVariant(row.item.review_status)">
                            <div class="dot"></div> {{ row.item.review_status }}
                          </b-badge> -->
                          <b-badge variant="light-danger" class="p-50"> <div class="dot"></div> Not Created</b-badge>
                          <b-button pill variant="outline-secondary">Send Alert</b-button>
                        </div>
                    </div>
                  </li>
                  <li class="list-unstyled my-1">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <a href="/profile/7ag6m" rel="noopener" target="_blank" class="b-avatar mr-75 badge-secondary rounded-circle" style="width: 40px; height: 40px; background: transparent;">
                          <span class="b-avatar-img">
                            <b-avatar src="../../../assets/images/avatars/1.png" alt="user-image" width="32px" height="32px"/>
                          </span>
                        </a>
                        <div class="profile-user-info mt-25 cursor-pointer">
                          <h6 class="mb-0">Annette Black</h6>
                          <small> uX Designer</small>
                        </div>
                      </div>
                        <div class="d-flex align-items-center gap-40">
                          <!-- <b-badge :variant="getStatusVariant(row.item.review_status)">
                            <div class="dot"></div> {{ row.item.review_status }}
                          </b-badge> -->
                          <b-badge variant="light-danger" class="p-50"> <div class="dot"></div> Not Created</b-badge>
                          <b-button pill variant="outline-secondary">Send Alert</b-button>
                        </div>
                    </div>
                  </li>
                  <li class="list-unstyled my-1">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <a href="/profile/7ag6m" rel="noopener" target="_blank" class="b-avatar mr-75 badge-secondary rounded-circle" style="width: 40px; height: 40px; background: transparent;">
                          <span class="b-avatar-img">
                            <b-avatar src="../../../assets/images/avatars/1.png" alt="user-image" width="32px" height="32px"/>
                          </span>
                        </a>
                        <div class="profile-user-info mt-25 cursor-pointer">
                          <h6 class="mb-0">Annette Black</h6>
                          <small> uX Designer</small>
                        </div>
                      </div>
                        <div class="d-flex align-items-center gap-40">
                          <!-- <b-badge :variant="getStatusVariant(row.item.review_status)">
                            <div class="dot"></div> {{ row.item.review_status }}
                          </b-badge> -->
                          <b-badge variant="light-danger" class="p-50"> <div class="dot"></div> Not Created</b-badge>
                          <b-button pill variant="outline-secondary">Send Alert</b-button>
                        </div>
                    </div>
                  </li>
                  <li class="list-unstyled my-1">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <a href="/profile/7ag6m" rel="noopener" target="_blank" class="b-avatar mr-75 badge-secondary rounded-circle" style="width: 40px; height: 40px; background: transparent;">
                          <span class="b-avatar-img">
                            <b-avatar src="../../../assets/images/avatars/1.png" alt="user-image" width="32px" height="32px"/>
                          </span>
                        </a>
                        <div class="profile-user-info mt-25 cursor-pointer">
                          <h6 class="mb-0">Annette Black</h6>
                          <small> uX Designer</small>
                        </div>
                      </div>
                        <div class="d-flex align-items-center gap-40">
                          <!-- <b-badge :variant="getStatusVariant(row.item.review_status)">
                            <div class="dot"></div> {{ row.item.review_status }}
                          </b-badge> -->
                          <b-badge variant="light-danger" class="p-50"> <div class="dot"></div> Not Created</b-badge>
                          <b-button pill variant="outline-secondary">Send Alert</b-button>
                        </div>
                    </div>
                  </li>
                  <li class="list-unstyled my-1">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <a href="/profile/7ag6m" rel="noopener" target="_blank" class="b-avatar mr-75 badge-secondary rounded-circle" style="width: 40px; height: 40px; background: transparent;">
                          <span class="b-avatar-img">
                            <b-avatar src="../../../assets/images/avatars/1.png" alt="user-image" width="32px" height="32px"/>
                          </span>
                        </a>
                        <div class="profile-user-info mt-25 cursor-pointer">
                          <h6 class="mb-0">Annette Black</h6>
                          <small> uX Designer</small>
                        </div>
                      </div>
                        <div class="d-flex align-items-center gap-40">
                          <!-- <b-badge :variant="getStatusVariant(row.item.review_status)">
                            <div class="dot"></div> {{ row.item.review_status }}
                          </b-badge> -->
                          <b-badge variant="light-danger" class="p-50"> <div class="dot"></div> Not Created</b-badge>
                          <b-button pill variant="outline-secondary">Send Alert</b-button>
                        </div>
                    </div>
                  </li>
                  <li class="list-unstyled my-1">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <a href="/profile/7ag6m" rel="noopener" target="_blank" class="b-avatar mr-75 badge-secondary rounded-circle" style="width: 40px; height: 40px; background: transparent;">
                          <span class="b-avatar-img">
                            <b-avatar src="../../../assets/images/avatars/1.png" alt="user-image" width="32px" height="32px"/>
                          </span>
                        </a>
                        <div class="profile-user-info mt-25 cursor-pointer">
                          <h6 class="mb-0">Annette Black</h6>
                          <small> uX Designer</small>
                        </div>
                      </div>
                        <div class="d-flex align-items-center gap-40">
                          <!-- <b-badge :variant="getStatusVariant(row.item.review_status)">
                            <div class="dot"></div> {{ row.item.review_status }}
                          </b-badge> -->
                          <b-badge variant="light-danger" class="p-50"> <div class="dot"></div> Not Created</b-badge>
                          <b-button pill variant="outline-secondary">Send Alert</b-button>
                        </div>
                    </div>
                  </li>
                </ul>
              </b-card>
            </b-col>
            <b-col md="6">
              <b-card>
                <h5>Zircly Team Progress Q3 2024 </h5>
                <div class="table-responsive mb-0 customize-okrtable main-table org-okrtable">
                  <table class="w-100">
                    <thead>
                      <!-- <tr>
                        <th>Team Member</th>
                        <th>Role</th>
                        <th>Progress</th>
                        <th>Status</th>
                      </tr> -->
                    </thead>
                    <tbody>
                      <tr>
                        <td class="profile" width="50%" style="min-width: 185px;">
                          <div class="d-flex align-items-center gap-10">
                            <b-avatar src="../../../assets/images/avatars/1.png" alt="user-image" width="32px" height="32px"/>
                            <div class="">
                              <h6 class="name mb-0">Gunaseelan</h6>
                              <small>UX Designer</small>
                            </div>
                          </div>
                        </td>
                        <td class="progress-cell">
                          <div class="d-flex align-items-center">
                            <div class="progress flat-progress">
                              <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="100" class="progress-bar" style="width: 100%;"></div>
                            </div>
                            <span class="ml-50">100%</span>
                          </div>
                        </td>
                        <td class="status-info">
                          <b-badge variant="light-primary">Completed</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <td class="profile" width="50%" style="min-width: 185px;">
                          <div class="d-flex align-items-center gap-10">
                            <b-avatar src="../../../assets/images/avatars/1.png" alt="user-image" width="32px" height="32px"/>
                            <div class="">
                              <h6 class="name mb-0">Gunaseelan</h6>
                              <small>UX Designer</small>
                            </div>
                          </div>
                        </td>
                        <td class="progress-cell">
                          <div class="d-flex align-items-center">
                            <div class="progress flat-progress">
                              <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="100" class="progress-bar" style="width: 100%;"></div>
                            </div>
                            <span class="ml-50">100%</span>
                          </div>
                        </td>
                        <td class="status-info">
                          <b-badge variant="light-primary">Completed</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <td class="profile" width="50%" style="min-width: 185px;">
                          <div class="d-flex align-items-center gap-10">
                            <b-avatar src="../../../assets/images/avatars/1.png" alt="user-image" width="32px" height="32px"/>
                            <div class="">
                              <h6 class="name mb-0">Gunaseelan</h6>
                              <small>UX Designer</small>
                            </div>
                          </div>
                        </td>
                        <td class="progress-cell">
                          <div class="d-flex align-items-center">
                            <div class="progress flat-progress">
                              <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="100" class="progress-bar" style="width: 100%;"></div>
                            </div>
                            <span class="ml-50">100%</span>
                          </div>
                        </td>
                        <td class="status-info">
                          <b-badge variant="light-primary">Completed</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <td class="profile" width="50%" style="min-width: 185px;">
                          <div class="d-flex align-items-center gap-10">
                            <b-avatar src="../../../assets/images/avatars/1.png" alt="user-image" width="32px" height="32px"/>
                            <div class="">
                              <h6 class="name mb-0">Gunaseelan</h6>
                              <small>UX Designer</small>
                            </div>
                          </div>
                        </td>
                        <td class="progress-cell">
                          <div class="d-flex align-items-center">
                            <div class="progress flat-progress">
                              <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="100" class="progress-bar" style="width: 100%;"></div>
                            </div>
                            <span class="ml-50">100%</span>
                          </div>
                        </td>
                        <td class="status-info">
                          <b-badge variant="light-primary">Completed</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <td class="profile" width="50%" style="min-width: 185px;">
                          <div class="d-flex align-items-center gap-10">
                            <b-avatar src="../../../assets/images/avatars/1.png" alt="user-image" width="32px" height="32px"/>
                            <div class="">
                              <h6 class="name mb-0">Gunaseelan</h6>
                              <small>UX Designer</small>
                            </div>
                          </div>
                        </td>
                        <td class="progress-cell">
                          <div class="d-flex align-items-center">
                            <div class="progress flat-progress">
                              <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="100" class="progress-bar" style="width: 100%;"></div>
                            </div>
                            <span class="ml-50">100%</span>
                          </div>
                        </td>
                        <td class="status-info">
                          <b-badge variant="light-primary">Completed</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <td class="profile" width="50%" style="min-width: 185px;">
                          <div class="d-flex align-items-center gap-10">
                            <b-avatar src="../../../assets/images/avatars/1.png" alt="user-image" width="32px" height="32px"/>
                            <div class="">
                              <h6 class="name mb-0">Gunaseelan</h6>
                              <small>UX Designer</small>
                            </div>
                          </div>
                        </td>
                        <td class="progress-cell">
                          <div class="d-flex align-items-center">
                            <div class="progress flat-progress">
                              <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="100" class="progress-bar" style="width: 100%;"></div>
                            </div>
                            <span class="ml-50">100%</span>
                          </div>
                        </td>
                        <td class="status-info">
                          <b-badge variant="light-primary">Completed</b-badge>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <!-- ONLY TEAM PROGRESS-->
           <b-row>
            <b-col md="12">
              <b-card>
                <h5>Zircly Team Progress Q3 2024 </h5>
                <div class="table-responsive mb-0 customize-okrtable main-table org-okrtable">
                  <table class="w-100">
                    <thead>
                      <tr>
                        <th>OKR Title</th>
                        <th>Role</th>
                        <th>Progress</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="profile" width="35%" style="min-width: 300px;">
                          <div class="d-flex align-items-center gap-10">
                            <b-avatar src="../../../assets/images/avatars/1.png" alt="user-image" width="32px" height="32px"/>
                            <div class="">
                              <h6 class="name mb-0">Gunaseelan</h6>
                            </div>
                          </div>
                        </td>
                        <td width="20%">
                          <h6 class="mb-0">UX Designer</h6>
                        </td>
                        <td class="progress-cell" width="25%">
                          <div class="d-flex align-items-center">
                            <div class="progress flat-progress">
                              <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="100" class="progress-bar" style="width: 100%;"></div>
                            </div>
                            <span class="ml-50">100%</span>
                          </div>
                        </td>
                        <td class="status-info" width="20%">
                          <b-badge variant="light-primary">Completed</b-badge>
                        </td>
                      </tr>
                      <tr>
                        <td class="profile" width="35%" style="min-width: 300px;">
                          <div class="d-flex align-items-center gap-10">
                            <b-avatar src="../../../assets/images/avatars/1.png" alt="user-image" width="32px" height="32px"/>
                            <div class="">
                              <h6 class="name mb-0">Gunaseelan</h6>
                            </div>
                          </div>
                        </td>
                        <td width="20%">
                          <h6 class="mb-0">UX Designer</h6>
                        </td>
                        <td class="progress-cell" width="25%">
                          <div class="d-flex align-items-center">
                            <div class="progress flat-progress">
                              <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="100" class="progress-bar" style="width: 100%;"></div>
                            </div>
                            <span class="ml-50">100%</span>
                          </div>
                        </td>
                        <td class="status-info" width="20%">
                          <b-badge variant="light-primary">Completed</b-badge>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card>
            </b-col>
           </b-row>
        </div>
        <!-- Details Section -->
        <b-row class="okr-details">
            <b-col md="3">
                <div class="okr-item my-okr">
                    <div class="d-flex align-items-center gap-6">
                        <h6 class="mb-0">My Q3 OKR</h6>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M7.9974 4.0013H3.9974C3.64377 4.0013 3.30464 4.14178 3.05459 4.39183C2.80454 4.64187 2.66406 4.98101 2.66406 5.33464V12.0013C2.66406 12.3549 2.80454 12.6941 3.05459 12.9441C3.30464 13.1942 3.64377 13.3346 3.9974 13.3346H10.6641C11.0177 13.3346 11.3568 13.1942 11.6069 12.9441C11.8569 12.6941 11.9974 12.3549 11.9974 12.0013V8.0013M7.33073 8.66797L13.3307 2.66797M13.3307 2.66797H9.9974M13.3307 2.66797V6.0013" stroke="#667085" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div class="circle_percent" percent="90%">
                        <div class="circle_inner">
                            <div class="round_per"></div>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col md="3">
                <div class="okr-item team-okr">
                    <div class="d-flex align-items-center gap-6">
                        <h6 class="mb-0">Zircl Q3 OKR</h6>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M7.9974 4.0013H3.9974C3.64377 4.0013 3.30464 4.14178 3.05459 4.39183C2.80454 4.64187 2.66406 4.98101 2.66406 5.33464V12.0013C2.66406 12.3549 2.80454 12.6941 3.05459 12.9441C3.30464 13.1942 3.64377 13.3346 3.9974 13.3346H10.6641C11.0177 13.3346 11.3568 13.1942 11.6069 12.9441C11.8569 12.6941 11.9974 12.3549 11.9974 12.0013V8.0013M7.33073 8.66797L13.3307 2.66797M13.3307 2.66797H9.9974M13.3307 2.66797V6.0013" stroke="#667085" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div class="circle_percent" percent="90%">
                        <div class="circle_inner">
                            <div class="round_per"></div>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col md="6">
                <div class="okr-item top-performing">
                    <div class="d-flex align-items-center gap-6 mb-1">
                        <h6 class="mb-0">Top Performing Q3 OKR</h6>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M7.9974 4.0013H3.9974C3.64377 4.0013 3.30464 4.14178 3.05459 4.39183C2.80454 4.64187 2.66406 4.98101 2.66406 5.33464V12.0013C2.66406 12.3549 2.80454 12.6941 3.05459 12.9441C3.30464 13.1942 3.64377 13.3346 3.9974 13.3346H10.6641C11.0177 13.3346 11.3568 13.1942 11.6069 12.9441C11.8569 12.6941 11.9974 12.3549 11.9974 12.0013V8.0013M7.33073 8.66797L13.3307 2.66797M13.3307 2.66797H9.9974M13.3307 2.66797V6.0013" stroke="#667085" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <ul class="progress-list p-0 m-0 progress-state">
                        <li class="list-unstyled ">
                            <div class="d-flex align-items-center justify-content-between">
                                <h6 class="mb-0">Kalaiselvic's Q3 OKR</h6>
                                <div class="d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-progress value="50%" max="100" class="flat-progress"></b-progress>
                                        <span class="ml-50">50%</span>
                                    </div>
                                    <b-badge variant="light-dark" class="ml-2">
                                        In Progress
                                    </b-badge>
                                </div>
                            </div>
                        </li>
                        <li class="list-unstyled">
                            <div class="d-flex align-items-center justify-content-between">
                                <h6 class="mb-0">Lokesh's Q3 OKR</h6>
                                <div class="d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-progress value="50%" max="100" class="flat-progress"></b-progress>
                                        <span class="ml-50">50%</span>
                                    </div>
                                    <b-badge variant="light-dark" class="ml-2">
                                        In Progress
                                    </b-badge>
                                    <!-- <b-badge :variant="getStatusVariant(objective.item.status)">
                                        <div class="dot"></div> {{ objective.item.status }}
                                    </b-badge> -->
                                </div>
                            </div>
                        </li>
                        <li class="list-unstyled">
                            <div class="d-flex align-items-center justify-content-between">
                                <h6 class="mb-0">Mugesh's Q3 OKR</h6>
                                <div class="d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-progress value="50%" max="100" class="flat-progress"></b-progress>
                                        <span class="ml-50">50%</span>
                                    </div>
                                    <b-badge variant="light-dark" class="ml-2">
                                        In Progress
                                    </b-badge>
                                </div>
                            </div>
                        </li>
                        <li class="list-unstyled">
                            <div class="d-flex align-items-center justify-content-between">
                                <h6 class="mb-0">Gunaseelan's Q3 OKR</h6>
                                <div class="d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-progress value="100%" max="100" class="flat-progress"></b-progress>
                                        <span class="ml-50">100%</span>
                                    </div>
                                    <b-badge variant="light-dark" class="ml-2">
                                        In Progress
                                    </b-badge>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </b-col>
        </b-row>
    </div>
   </template>
   <script>
   import $ from "jquery";
   import {
     BCard,
     BCardHeader,
     BCardTitle,
     BTable,
     BRow,
     BCol,
     BFormGroup,
     BFormInput,
     BFormCheckbox,
     BFormRadio,
     BForm,
     BInputGroup,
     BInputGroupPrepend,
     BTabs,
     BTab,
     BListGroup,
     BListGroupItem,
     VBTooltip,
   } from "bootstrap-vue";
  //  import { treeTable } from "./tree-table";
   import { BAvatar, BModal, BImg, BButton } from 'bootstrap-vue';
   import Vue from 'vue';
   import axios from "@axios";
   import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
   export default {
     name: 'example',
     components: {
     },
     created() {
       this.pageType = this.getPageType();
       this.getEmployeeOptions();
       this.getManagerOptions();
       this.getTeamOptions();
       const urlParams = new URLSearchParams(window.location.search);
       const userId = urlParams.get('employee_name');
       this.filters.searchQuery = userId ? userId : ""
     },
     watch: {
       pageType:function (val) {
         this.getItems();
       },
       '$route.fullPath'(newPath, oldPath) {
         this.pageType = this.getPageType();
         this.resetPagination();
         // Call your function here or perform actions based on the URL change
       },
       'filters.typeFilter':function (val) {
         this.resetPagination();
       },
       filters: {
         handler(newFilters, oldFilters) {
           // Call your function or perform actions here when filters change
           this.getItems();
         },
         deep: true, // Watch all properties of the filters object
       },
       
     '$route.query': function(val) {
   
       const filter = val.q; // Access the 'q' parameter directly from val
       console.log(filter)
       if (filter) {
         this.filters.searchQuery = filter; // Update the searchQuery data property
       }
     
   },
     },
     data() {
       return {
         pageType:null,
         props: {
           stripe: false,
           border: false,
           showHeader: true,
           showSummary: false,
           showRowHover: true,
           showIndex: false,
           treeType: true,
           isFold: true,
           expandType: false,
           selectionType: false,
         },
         teamOptions: [],
         userData: this.$cookies.get("userData"),
         items: [],
       };
     },
     methods: {
       updateProgress(){
         // Circle Progress bar Design
         $(".circle_percent").each(function() {
           var $this = $(this),
           $dataV = $this.attr('percent'),
           $dataDeg = $dataV * 3.6,
           $round = $this.find(".round_per");
           $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");	
           $this.append('<div class="circle_inbox"><span class="percent_text"></span></div>');
           $this.prop('Counter', 0).animate({Counter: $dataV},
           {
             duration: 2000, 
             easing: 'swing', 
             step: function (now) {
                     $this.find(".percent_text").text(Math.ceil(now)+"%");
                 }
             });
           if($dataV >= 51){
             $round.css("transform", "rotate(" + 360 + "deg)");
             setTimeout(function(){
               $this.addClass("percent_more");
             },1000);
             setTimeout(function(){
               $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
             },1000);
           } 
         });
       },
       getEmployeeOptions(){
         axios
         .get("/list/extended-employees")
         .then(response => {
           console.log(response);
             this.employeeOptions = response.data.data;
         })
         .catch((error) => {
             console.log(error);
             // this.$toast({
             //   component: ToastificationContent,
             //   props: {
             //     title: "Oops! Unable to fetch Employees",
             //     text: error.message,
             //     icon: "BellIcon",
             //     variant: "danger",
             //   },
             // });
             this.$toast({
                 component: ToastificationContent,
                 props: {
                   title: 'Oops! Unable to fetch Employees',
                   icon: 'BellIcon',
                   variant: 'danger',
                   text: error.message,
                 },
               }, {
                 timeout: 2000, 
                 position: 'bottom-center',
                 toastClassName:"radius-8 error-info ",
                 hideProgressBar : true,
               });
           });
       },
       getTeamOptions() {
         this.isloading = true;
         axios.get("/list/divisions")
           .then((response) => {
             this.teamOptions = response.data.data;
           })
           .catch((error) => {
             // this.$toast({
             //   component: ToastificationContent,
             //   props: {
             //     title: "Oops! Unable to fetch Team Names",
             //     icon: "BellIcon",
             //     variant: "danger",
             //     text: error,
             //   },
             // });
             this.$toast({
                 component: ToastificationContent,
                 props: {
                   title: 'Oops! Unable to fetch Team Names',
                   icon: 'BellIcon',
                   variant: 'danger',
                   text: error,
                 },
               }, {
                 timeout: 2000, 
                 position: 'bottom-center',
                 toastClassName:"radius-8 error-info ",
                 hideProgressBar : true,
               });
           });
       },
       getManagerOptions(){
         axios
         .get("/list/extended-managers")
         .then(response => {
             this.managerOptions = response.data.data;
         })
         .catch((error) => {
             console.log(error);
             // this.$toast({
             //   component: ToastificationContent,
             //   props: {
             //     title: "Oops! Unable to fetch Employees",
             //     text: error.message,
             //     icon: "BellIcon",
             //     variant: "danger",
             //   },
             // });
             this.$toast({
                 component: ToastificationContent,
                 props: {
                   title: 'Oops! Unable to fetch Employees',
                   icon: 'BellIcon',
                   variant: 'danger',
                   text: error.message,
                 },
               }, {
                 timeout: 2000, 
                 position: 'bottom-center',
                 toastClassName:"radius-8 error-info ",
                 hideProgressBar : true,
               });
           });
       },
       resetFilters(){
         this.filters = {
           typeFilter: this.filters.typeFilter,
           searchQuery: '',
           employeeFilter: [],
           managerFilter: [],
           quarterFilter: '',
           yearFilter: '',
           statusFilter: '',
           currentPage: 1,
           teamFilter: '',
           reviewStatusFilter:'',
         };
   
         this.selectedEmployees = [];
         this.selectedManagers = [];
         this.searchEmployee = '';
         this.searchManager ='';
         this.resetPagination();
       },
       getItems(){
         if(this.pageType == 'my-okrs'){
           this.fields=this.myOKR_fields;
           this.expand_fields=this.key_result_fields;
           this.getAllMyOKRs();
         }
         if(this.pageType == 'review-okrs'){
           this.fields=this.review_feilds;
           this.getMySubordinatesOKRs();
         }
         if(this.pageType == 'all-okrs'){
           if(this.filters.typeFilter == 'Organisation'){
               this.fields=this.AllOKRs_fields.organisation_feilds;
               this.expand_fields=this.key_result_fields;
               this.getOrganisationOKR();
           }
           if(this.filters.typeFilter == 'Team'){
               this.fields=this.AllOKRs_fields.team_feilds;
               this.getTeamOKR();
           }
           if(this.filters.typeFilter == 'Individual'){
               this.fields=this.AllOKRs_fields.individual_feilds;
               this.expand_fields = this.objective_fields;
               this.getIndivudualOKR();
           }
         }
       },
       getPageType(){
         const currentPath = window.location.pathname;
         const segments = currentPath.split('/');
         // Filter out any empty segments (e.g., due to leading or trailing slashes)
         const filteredSegments = segments.filter(segment => segment.trim() !== '');
         return filteredSegments[filteredSegments.length - 1];
       },
       confirmCloseButton() {
         this.$swal({
           title: "Are you sure?",
           text: "You are about to exit this form , if you proceed the form data will be lost.",
           showCancelButton: true,
           confirmButtonText: "Yes",
           customClass: {
             confirmButton: "btn yes-btn",
             cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
           },
           buttonsStyling: false,
         }).then((result) => {
           if (result.value) {
             this.$bvModal.hide("create-custompop");
             this.post_form_data = {
               title: '',
               channel_type: "community",
               post_anonymously: false,
               feedback_type: "",
               body: "",
               scheduled_time: moment().add(1, "days").format("YYYY-MM-DD ") + "09:30",
               scheduled_timezone: this.getUserTimezone(),
               is_scheduled: false,
               other_details: {
                 post_type: 'text-post',
                 author: ''
               }
             };
             this.myFiles = [];
             this.page2 = false;
           }
         });
       },
       switchTo(type){
         this.filters.typeFilter = type;
       },
       resetPagination(){
         this.filters.currentPage = 1;
         this.per_page = 10;
       },
       getStatusVariant(status){
         if(status == 'Upcoming'){
           return 'light-info';
         }else if(status == 'Not Started Yet'){
           return 'light-notyet';
         }else if(status == 'In Progress'){
           return 'light-warning';
         }else if(status == 'Completed'){
           return 'light-primary';
         }else if(status == 'Cancelled'){
           return 'light-danger';
         }else if(status == 'On Hold'){
           return 'light-dark';
         }else if(status == 'Deferred'){
           return 'light-dark';
         }else if(status == 'Submitted'){
           return 'light-dark';
         }else if(status == 'Approved'){
           return 'light-primary';
         }
         return 'light-warning';
       },
       getAllMyOKRs(){
         var params = {
           typeFilter : this.filters.typeFilter,
           searchQuery : this.filters.searchQuery,
           quarterFilter : this.filters.quarterFilter,
           statusFilter : this.filters.statusFilter,
           yearFilter : this.filters.yearFilter,
           currentPage : this.filters.currentPage,
           per_page : this.per_page,
           teamFilter : this.filters.teamFilter.label
         }
         axios
         .post("objectives/my-okrs", { params: params })
         .then(response => {
             this.items = response.data.data;
             this.total = response.data.meta.total;
             this.per_page = response.data.meta.per_page;
         })
         .catch((error) => {
             console.log(error);
             // this.$toast({
             //   component: ToastificationContent,
             //   props: {
             //     title: "Oops! Unable to fetch OKRs",
             //     text: error.message,
             //     icon: "BellIcon",
             //     variant: "danger",
             //   },
             // });
             this.$toast({
                 component: ToastificationContent,
                 props: {
                   title: 'Oops! Unable to fetch OKRs',
                   icon: 'BellIcon',
                   variant: 'danger',
                   text: error.message,
                 },
               }, {
                 timeout: 2000, 
                 position: 'bottom-center',
                 toastClassName:"radius-8 error-info ",
                 hideProgressBar : true,
               });
           });
       },
       getOrganisationOKR(){
         var params = {
           typeFilter : this.filters.typeFilter,
           searchQuery : this.filters.searchQuery,
           quarterFilter : this.filters.quarterFilter,
           statusFilter : this.filters.statusFilter,
           yearFilter : this.filters.yearFilter,
           currentPage : this.filters.currentPage,
           per_page : this.per_page,
           teamFilter : this.filters.teamFilter.label,
         }
         axios
         .post("objectives/all-organisation-okrs", { params: params })
         .then(response => {
             this.items = response.data.data;
             this.total = response.data.meta.total;
             this.per_page = response.data.meta.per_page;
         })
         .catch((error) => {
             console.log(error);
             // this.$toast({
             //   component: ToastificationContent,
             //   props: {
             //     title: "Oops! Unable to fetch Orgnisation OKRs",
             //     text: error.message,
             //     icon: "BellIcon",
             //     variant: "danger",
             //   },
             // });
             this.$toast({
                 component: ToastificationContent,
                 props: {
                   title: 'Oops! Unable to fetch Orgnisation OKRs',
                   icon: 'BellIcon',
                   variant: 'danger',
                   text: error.message,
                 },
               }, {
                 timeout: 2000, 
                 position: 'bottom-center',
                 toastClassName:"radius-8 error-info ",
                 hideProgressBar : true,
               });
           });
       },
       getIndivudualOKR(){
         var params = {
           typeFilter : this.filters.typeFilter,
           searchQuery : this.filters.searchQuery,
           quarterFilter : this.filters.quarterFilter,
           statusFilter : this.filters.statusFilter,
           yearFilter : this.filters.yearFilter,
           currentPage : this.filters.currentPage,
           per_page : this.per_page,
           teamFilter : this.filters.teamFilter.label,
           employeeFilter: this.filters.employeeFilter,
           managerFilter: this.filters.managerFilter,
         }
         axios
         .post("objectives/all-individual-okrs", { params: params })
         .then(response => {
             this.items = response.data.data;
             this.total = response.data.pagination.total;
             this.per_page = response.data.pagination.per_page;
         })
         .catch((error) => {
             console.log(error);
             // this.$toast({
             //   component: ToastificationContent,
             //   props: {
             //     title: "Oops! Unable to fetch Individual OKRs",
             //     text: error.message,
             //     icon: "BellIcon",
             //     variant: "danger",
             //   },
             // });
             this.$toast({
                 component: ToastificationContent,
                 props: {
                   title: 'Oops! Unable to fetch Individual OKRs',
                   icon: 'BellIcon',
                   variant: 'danger',
                   text: error.message,
                 },
               }, {
                 timeout: 2000, 
                 position: 'bottom-center',
                 toastClassName:"radius-8 error-info ",
                 hideProgressBar : true,
               });
              
           });
       },
       getTeamOKR(){
         var params = {
           typeFilter : this.filters.typeFilter,
           searchQuery : this.filters.searchQuery,
           quarterFilter : this.filters.quarterFilter,
           statusFilter : this.filters.statusFilter,
           yearFilter : this.filters.yearFilter,
           currentPage : this.filters.currentPage,
           per_page : this.per_page,
           teamFilter : this.filters.teamFilter.label,
           employeeFilter: this.filters.employeeFilter,
           managerFilter: this.filters.managerFilter,
         }
         axios
         .post("objectives/all-team-okrs", { params: params })
         .then(response => {
             this.items = response.data.data;
             this.total = response.data.pagination.total;
             this.per_page = response.data.pagination.per_page;
         })
         .catch((error) => {
             console.log(error);
             // this.$toast({
             //   component: ToastificationContent,
             //   props: {
             //     title: "Oops! Unable to fetch Team OKRs",
             //     text: error.message,
             //     icon: "BellIcon",
             //     variant: "danger",
             //   },
             // });
             this.$toast({
                 component: ToastificationContent,
                 props: {
                   title: 'Oops! Unable to fetch Team OKRs',
                   icon: 'BellIcon',
                   variant: 'danger',
                   text: error.message,
                 },
               }, {
                 timeout: 2000, 
                 position: 'bottom-center',
                 toastClassName:"radius-8 error-info ",
                 hideProgressBar : true,
               });
           });
       },
       checkData(objectiveData) {
         var messages = [];
         if (objectiveData.title == ''){
           messages.push({'title':'Objective title is required','text':'Please enter objective title.'});       
         }
         // if (objectiveData.type != 'individual'){
         //   messages.push({'title':'Objective title is required','text':'Please enter objective title.'});       
         // }
         objectiveData.key_results.forEach(function(key_result,key) { 
           if(key_result.result_description == '' || key_result.result_description == null ){
             messages.push({'title':"Key Result is required #"+(key+1),'text':"Please Enter Key Result #"+(key+1)+" for Objective "});
           }
           if(key_result.metric_type == 'Number' || key_result.metric_type == 'Percentage' ){
             if(key_result.goal == '' || key_result.goal == null || key_result.goal == 0 || !(!isNaN(key_result.goal) && !isNaN(parseInt(key_result.goal)))){
               messages.push({'title':"Goal "+key_result.metric_type+" is required #"+(key+1),'text':"Please Valid Enter Goal "+key_result.metric_type+" for Key Result #"+(key+1)});
             }
             if((key_result.start < 0 || key_result.start == '' || key_result.start == null  || !(!isNaN(key_result.start) && !isNaN(parseInt(key_result.start))))){
               if(key_result.start != 0){
                 if(key_result.start < 0){
                   messages.push({'title':"Start "+key_result.metric_type+" is invalid #"+(key+1),'text':"Start "+key_result.metric_type+ "must be zero or positive for Key Result #"+(key+1)});
                 }else{
                   messages.push({'title':"Start "+key_result.metric_type+" is required #"+(key+1),'text':"Please Enter Valid Start "+key_result.metric_type+" for Key Result #"+(key+1)});
                 }
               }
             }
   
             if(objectiveData.hashid){
               if(( key_result.progress == '' || key_result.progress == null  || !(!isNaN(key_result.progress) && !isNaN(parseInt(key_result.progress))))){
                 messages.push({'title':"Progress "+key_result.metric_type+" is required #"+(key+1),'text':"Please Enter Valid Progress "+key_result.metric_type+" for Key Result #"+(key+1)});
               }
             }
             if(parseInt(key_result.start) >= parseInt(key_result.goal)){
               messages.push({'title':"Start "+key_result.metric_type+" is required #"+(key+1),'text':"Start must be less than Target for Key Result #"+(key+1)});
             }
             if(key_result.progress && parseInt(key_result.progress) < parseInt(key_result.start)){
               messages.push({'title':"Progress "+key_result.metric_type+" is not valid for #"+(key+1),'text':"Progress must be grater than Start("+key_result.start+") for Key Result #"+(key+1)});
             }
             
           }
         });
   
         var response = {"status":false,'messages':messages};
         if(messages.length == 0){
           var response = {"status":true,'messages':messages};
         }
         return response;      
       },
       updateObjectiveProgress(objective){
         var validData = this.checkData(objective);
         if(validData.status){
           axios.patch('objectives/'+objective.hashid, objective)
           .then((res) => {
               this.selectedObjective = res.data.data;
               if(this.selectedRow){
                 for (let index = 0; index < this.selectedRow.objectives.length; index++) {
                   const element = this.selectedRow.objectives[index];
                   if(element.hashid == objective.hashid){
                     this.selectedRow.objectives[index] = res.data.data;
                     break;
                   }            
                 }
             }
             this.getItems();
             // this.$toast({
             //   component: ToastificationContent,
             //   props: {
             //     title: "OKR Updated Successfully",
             //     icon: "BellIcon",
             //     variant: "success",
             //     text: res.data.message,
             //   },
             // });
             this.$toast({
                 component: ToastificationContent,
                 props: {
                   title: 'OKR Updated Successfully',
                   icon: 'BellIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               }, {
                 timeout: 2000, 
                 position: 'bottom-center',
                 toastClassName:"radius-8 successful-info",
                 hideProgressBar : true,
               });
           })
           .catch((error) => {
             console.log(error)
             this.getMyOkRs();
             // this.$toast(
             //   {
             //     component: ToastificationContent,
             //     props: {
             //       title: "Oops! OKR Adding Failed",
             //       icon: "BellIcon",
             //       variant: "danger",
             //       text: error,
             //     },
             //   });
             this.$toast({
                 component: ToastificationContent,
                 props: {
                   title: 'Oops! OKR Adding Failed',
                   icon: 'BellIcon',
                   variant: 'danger',
                   text: error,
                 },
               }, {
                 timeout: 2000, 
                 position: 'bottom-center',
                 toastClassName:"radius-8 error-info ",
                 hideProgressBar : true,
               });
           });
         }
         else{
           for(var key in validData.messages){
           //  this.$toast(
           //     {
           //       component: ToastificationContent,
           //       props: {
           //         title: validData.messages[key].title,
           //         text:validData.messages[key].text,
           //         icon: "BellIcon",
           //         variant: "danger",
           //       },
           //     }); 
           this.$toast({
                 component: ToastificationContent,
                 props: {
                   title: validData.messages[key].title,
                   icon: 'BellIcon',
                   variant: 'danger',
                   text: validData.messages[key].text,
                 },
               }, {
                 timeout: 2000, 
                 position: 'bottom-center',
                 toastClassName:"radius-8 error-info ",
                 hideProgressBar : true,
               });
           }
         }
       },
   
       getMySubordinatesOKRs(){
         var params = {
           typeFilter : 'Individual',
           searchQuery : this.filters.searchQuery,
           quarterFilter : this.filters.quarterFilter,
           statusFilter : this.filters.statusFilter,
           yearFilter : this.filters.yearFilter,
           currentPage : this.filters.currentPage,
           per_page : this.per_page,
           teamFilter : this.filters.teamFilter.label,
           reviewStatusFilter : this.filters.reviewStatusFilter,
           employeeFilter: this.filters.employeeFilter,
         }
         axios
         .post("objectives/review-okrs", { params: params })
         .then(response => {
             this.items = response.data;
         })
         .catch((error) => {
             console.log(error);
             // this.$toast({
             //   component: ToastificationContent,
             //   props: {
             //     title: "Oops! Unable to fetch Review OKRs",
             //     text: error.message,
             //     icon: "BellIcon",
             //     variant: "danger",
             //   },
             // });
             this.$toast({
                 component: ToastificationContent,
                 props: {
                   title: 'Oops! Unable to fetch Review OKRs',
                   icon: 'BellIcon',
                   variant: 'danger',
                   text: error.message,
                 },
               }, {
                 timeout: 2000, 
                 position: 'bottom-center',
                 toastClassName:"radius-8 error-info ",
                 hideProgressBar : true,
               });
           });
       }
     },
     computed: {
       propList() {
         return Object.keys(this.props).map(item => ({
           name: item,
         }));
       },
       filteredManagerOptions(){
         let filteredData = this.managerOptions.filter(item =>
           item.name.toLowerCase().includes(this.searchManager.toLowerCase()) || item.email.toLowerCase().includes(this.searchManager.toLowerCase())
         );
         return filteredData;
       },
       filteredEmployeeOptions(){
         let filteredData = this.employeeOptions.filter(item =>
           item.name.toLowerCase().includes(this.searchEmployee.toLowerCase()) || item.email.toLowerCase().includes(this.searchEmployee.toLowerCase())
         );
         return filteredData;
       },
       getClass(){
         if(this.pageType == 'all-okrs'){
           if(this.filters.typeFilter == 'Individual'){
             return 'individual-okrtable';
           }
           if(this.filters.typeFilter == 'Team'){
             return 'team-okrtable';
           }
           if(this.filters.typeFilter == 'Organisation'){
             return 'org-okrtable organisation-okr';
           }
         }
         return 'org-okrtable';
       }
   
     },
   
   };
   
   </script>
   <style lang="scss">
   @import "@core/scss/vue/libs/vue-select.scss";
   @import "@core/scss/vue/libs/vue-flatpicker.scss";
   </style>